import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';

import logo from './caebes-logo.svg';
import './App.css';
import SECRETS from './secrets';

const socket = io(SECRETS.SOCKETURL);

const pageContainer = {
  height: '100vh',
  width: '100vw',
  backgroundColor: '#f7fbee',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};

const pageContainerDark = {
  height: '100vh',
  width: '100vw',
  backgroundColor: '#091419',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
};

const logoContainer = {
  height: '200px',
  padding: '46px',
};

const logoContainerDark = {
  ...logoContainer,
};

const exitButton = {
  fontSize: '20px',
  marginRight: '6px',
  padding: '0px 0px',
  border: 'solid 2px rgba(0,0,0,0.4)',
  borderRadius: '4px',
  color: 'rgba(0,0,0,0.6)',
  width: '250px',
  height: '60px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const selectorContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const playButton = {
  fontSize: '20px',
  cursor: 'pointer',
  color: 'rgba(255,255,255,0.8)',
  background: '#7fb03a',
  border: '0',
  height: '60px',
  width: '250px',
  borderRadius: '5px',
  boxShadow: '0 1px 3px hsla(0, 0%, 0%, .2)',
};

const playButtonDark = {
  ...playButton,
  color: 'rgba(0,0,0,0.6)',
  backgroundColor: '#e9bacd',
};

const selectStyle = {
  height: '60px',
  width: '250px',
  fontSize: '20px',
  margin: '12px 0',
  border: '2px solid rgba(0, 0, 0, 0.1)',
  borderRadius: '3px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  color: 'rgba(0, 0, 0, 0.7)',
};

const spectrumContainer = {
  display: 'flex',
};

const twoDigits = (num) => {
  const numTwoDigits = (num <= 9) ? `0${num}` : `${num}`;
  return numTwoDigits;
};

const MainScreen = ({
  setId,
  token,
  setToken,
  operador,
  setOperador,
}) => {
  const [operadores, setOperadores] = useState([]);
  const [recorridos, setRecorridos] = useState([]);
  const [checadas, setChecadas] = useState([]);
  const [puntos, setPuntos] = useState([]);
  const [rutas, setRutas] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [isTalking, setIsTalking] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [voice, setVoice] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const esMx = window.speechSynthesis.getVoices().filter((f) => /.*mx.*/i.test(f.lang));
    const esES = window.speechSynthesis.getVoices().filter((f) => /.*es.*ES.*/i.test(f.lang));
    const esAny = window.speechSynthesis.getVoices().filter((f) => /.*es.*/.test(f.lang));
    if (esMx.length > 0) {
      setVoice(esMx[0]);
      console.log(esMx[0]);
    } else if (esES.length > 0) {
      setVoice(esES[0]);
      console.log(esES[0]);
    } else if (esAny.length > 0) {
      setVoice(esAny[0]);
      console.log(esAny[0]);
    }
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controloperador')
      .then((response) => {
        setOperadores(response.data);
      })
      .catch((e) => {
        console.error(e);
        if (e.response.status === 401) {
          setId(0);
          setToken('');
        }
      });
  }, []);

  useEffect(() => {
    const date = new Date();
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .post('/controlrecorrido/active', {
        fecha: `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`,
      })
      .then((response) => {
        const recorridosActivos = response.data.filter((r) => !r.terminado && !r.cancelado);
        setRecorridos(recorridosActivos);
        setChecadas((ch) => {
          let flatChecadas = [...ch];
          recorridosActivos.forEach((reco) => {
            flatChecadas = [
              ...flatChecadas,
              ...reco.puntos.map((punto) => ({
                ...punto,
                imei: reco.imei,
                nombre: punto.nombrePunto,
                date: punto.fecha,
              })),
            ];
          });
          return flatChecadas;
        });
      });
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/controlruta')
      .then((response) => {
        setRutas(response.data);
        response.data.forEach(({ id: idControlRuta }) => {
          axios.defaults.headers.common.Authorization = `bearer ${token}`;
          axios.defaults.baseURL = SECRETS.SERVERURL;
          axios
            .get(`/controlpunto/byRuta/${idControlRuta}`)
            .then((resp) => {
              setPuntos((pun) => [...pun, ...resp.data]);
            })
            .catch((e) => {
              console.error(e);
            });
        });
      });
  }, []);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `bearer ${token}`;
    axios.defaults.baseURL = SECRETS.SERVERURL;
    axios
      .get('/unidades/organizacion')
      .then((response) => {
        setUnidades(response.data);
      });
  }, []);

  useEffect(() => {
    if (isTalking) {
      const msg = new SpeechSynthesisUtterance();
      msg.text = mensaje;
      if (voice.name) {
        msg.voice = voice;
      } else {
        msg.lang = 'es';
      }
      window.speechSynthesis.speak(msg);
    }
  }, [mensaje, voice, isTalking]);

  useEffect(() => {
    const getText = (diff, nombreUnidad, diffFromFrontUnit, nombreGeocerca, isLastOne) => {
      const textoUnidadEnfrente = isLastOne
        ? '. Recorrido finalizado. Para iniciar un nuevo recorrido presiona el botón verde cuando te den tu salida'
        : `, y vas a ${diffFromFrontUnit} minutos de la unidad ${nombreUnidad}`;
      return `llevas ${Math.abs(diff)} minutos ${diff < 0 ? 'de adelanto' : 'de atraso'} en ${nombreGeocerca}${textoUnidadEnfrente}`;
    };

    const onConnect = () => {
      console.log('connected');
      const msg = new SpeechSynthesisUtterance();
      msg.text = 'Conectado';
      if (voice.name) {
        msg.voice = voice;
      } else {
        msg.lang = 'es';
      }
      window.speechSynthesis.speak(msg);
      socket.emit('login', token);
    };
    const onDisconnect = () => {
      const msg = new SpeechSynthesisUtterance();
      msg.text = 'Sin conexión a internet';
      if (voice.name) {
        msg.voice = voice;
      } else {
        msg.lang = 'es';
      }
      window.speechSynthesis.speak(msg);
      console.log(socket.id);
    };
    const onPuntoControl = (data) => {
      const { imei } = data;
      const op = parseInt(`${operador}`, 10);
      const recorrido = recorridos.find((r) => (
        r.idControlOperador === op && !r.terminado && !r.cancelado
      ));
      setChecadas((chec) => {
        const cloneChec = [...chec];
        // si ya existe el punto, no meter uno nuevo.
        const index = cloneChec
          .findIndex((cc) => cc.imei === data.imei && cc.idControlPunto === data.idControlPunto);
        if (index === -1) {
          if (recorrido && recorrido.imei === imei) {
            console.log(data);
            const inicio = new Date(recorrido.inicio);
            const checadaTime = new Date(data.date);
            const checada = puntos.find((p) => p.id === data.idControlPunto);
            const lastPointInRoute = puntos
              .filter((p) => p.idControlRuta === checada.idControlRuta)
              .sort((a, b) => b.tiempo - a.tiempo)[0];
            const tiempoDesdeBase = (Math.floor(checadaTime / 1000 / 60) - Math.floor(inicio / 1000 / 60));
            const diff = tiempoDesdeBase - checada.tiempo;
            const ruta = rutas.find((r) => r.id === recorrido.idControlRuta);
            const rutasConjuntoIds = rutas
              .filter((r) => {
                if (ruta.idConjunto === 0) {
                  return r.id === ruta.id;
                }
                return r.idConjunto === ruta.idConjunto;
              })
              .map((r) => r.id);

            const puntosConjuntoIds = puntos
              .filter((pc) => rutasConjuntoIds.includes(pc.idControlRuta))
              .map((pc) => pc.id);

            const unidadEnfrente = checadas
              .filter((ch) => {
                if (ruta.idConjunto === 0) {
                  return ch.idControlPunto === data.idControlPunto;
                }
                return puntosConjuntoIds.includes(ch.idControlPunto);
              })
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .find((ch) => ch.nombre === data.nombre && data.imei !== ch.imei);
            console.log(checadas
              .filter((ch) => {
                if (ruta.idConjunto === 0) {
                  return ch.idControlPunto === data.idControlPunto;
                }
                return puntosConjuntoIds.includes(ch.idControlPunto);
              })
              .sort((a, b) => new Date(b.date) - new Date(a.date)));
            const nombreUnidad = (unidades.find((u) => u.imei === unidadEnfrente.imei))?.unidad;
            console.log('frontunit', unidadEnfrente);
            const frontUnitTime = Math.floor(new Date(unidadEnfrente.date) / 1000 / 60);
            const diffFromFrontUnit = Math.floor(checadaTime / 1000 / 60) - frontUnitTime;
            const msg = getText(diff, nombreUnidad, diffFromFrontUnit, data.nombre, data.idControlPunto === lastPointInRoute.id);
            console.log(msg);
            setMensaje(msg);
            if (data.idControlPunto === lastPointInRoute.id) {
              setIsTalking(false);
            }
          }
          // setChecadas
          return [
            data,
            ...cloneChec,
          ];
        }
        return cloneChec;
      });
    };
    const onStatus = ({ status, recorrido }) => {
      // cuando llega un recorrido nuevo, eliminar todos los puntos de esa unidad
      console.log(status, recorrido);
      switch (status) {
        case 'terminado':
        case 'cancelado':
          setRecorridos((recSnap) => recSnap.filter((rec) => rec.id !== recorrido.id));
          setChecadas((chSnap) => chSnap.filter((ch) => ch.idControlRecorrido !== recorrido.id));
          break;
        case 'nuevo':
          setChecadas((chSnap) => chSnap.filter((ch) => ch.idControlRecorrido !== recorrido.id));
          setRecorridos((recSnap) => {
            const newRec = recSnap
              .filter((rec) => rec.imei !== recorrido.imei);
            return [...newRec, recorrido];
          });
          break;
        default:
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    // socket.on('location', (data) => {
    //   console.log(data);
    // });
    socket.on('PuntoControl', onPuntoControl);
    socket.on('status', onStatus);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('PuntoControl', onPuntoControl);
      socket.off('status', onStatus);
    };
  }, [operador, recorridos, unidades, rutas, puntos, checadas, voice]);

  const handleOperador = (e) => {
    setOperador(e.target.value);
  };

  const handleIniciar = () => {
    console.log(operador, operadores);
    if (operador && operadores.length) {
      const { nombre } = (operadores.find((o) => o.id === parseInt(operador, 10)));
      console.log(nombre.split(' ')[0]);
      const msg = new SpeechSynthesisUtterance();
      msg.text = `Hola ${nombre.split(' ')[0]}, estás conectado. 
        Yo soy tu asistente personal y te voy a acompañar en tu recorrido.`;
      if (voice.name) {
        msg.voice = voice;
      } else {
        msg.lang = 'es';
      }
      msg.rate = 1;
      window.speechSynthesis.speak(msg);
    }
    setIsTalking(true);
  };

  const handleClose = () => {
    setToken('');
    setOperador('');
    navigate('/');
  };

  return (
    <div>
      {!isTalking && (
        <div style={pageContainer}>
          <div style={logoContainer}>
            <img style={{ height: '100%', width: '100%' }} src={logo} alt="logo caebes" />
          </div>
          <div>
            <label htmlFor="operador-id" style={selectorContainer}>
              <select name="operador" id="operador-id" style={selectStyle} value={operador} onChange={(e) => handleOperador(e)}>
                <option value="0">Selecciona tu nombre:</option>
                {operadores.map((op) => <option value={op.id} key={`op-${op.id}`}>{op.nombre}</option>)}
              </select>
            </label>
          </div>
          <div style={selectorContainer}>
            <button type="button" style={playButton} onClick={handleIniciar}>
              Comenzar
            </button>
          </div>
          <div style={exitButton} onClick={handleClose}>
            Cerrar Sesión
          </div>
        </div>
      )}
      {isTalking && (
        <div style={pageContainerDark} className="slideIn">
          <div style={logoContainerDark}>
            <img style={{ height: '100%', width: '100%' }} src={logo} alt="logo caebes" />
          </div>
          <div style={spectrumContainer}>
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
            <div className="wave" />
          </div>
          <div>
            <button type="button" style={playButtonDark} onClick={() => setIsTalking(false)}>
              Detener
            </button>
          </div>
        </div>
      )}
      {window.speechSynthesis.getVoices().filter((f) => /.*es.*/.test(f.lang)).map((v) => (
        <p>
          {`${v.name} ${v.lang}`}
        </p>
      ))}
      <div>
        {`Selected voice: ${voice.name} - ${voice.lang}`}
      </div>
    </div>
  );
};

export default MainScreen;

import React, { useState, useEffect } from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';

import Login from './Login';
import MainScreen from './MainScreen';

const useStorageState = (key, initialState) => {
  const [value, setValue] = useState(
    localStorage.getItem(key) || initialState,
  );
  useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, key]);
  return [value, setValue];
};

const App = () => {
  const [id, setId] = useStorageState('id', 0);
  const [token, setToken] = useStorageState('token', '');
  const [operador, setOperador] = useStorageState('operador', '');

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={<Login setId={setId} setToken={setToken} id={id} token={token} />}
        />
        <Route
          path="/main"
          element={(
            <MainScreen
              id={id}
              token={token}
              setId={setId}
              setToken={setToken}
              operador={operador}
              setOperador={setOperador}
            />
          )}
        />
      </Routes>
    </HashRouter>
  );
};

export default App;
